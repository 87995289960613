import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { Form, Input, Row, Col } from "antd";
import { FormContainer, FormItem, StyledInput, StyledTextArea, StyledSelect, StyledLabel, PButton } from "./styled";
import Loading from "../../../components/Loading";
import ModalContactSuccess from "../../Modals/ModalContactSuccess";
import Image from 'next/image';
import { EEAT, EEATWRAP, EEATText } from "./styled";
import { jsonData } from "../../../components/LocationsMap/_data";
import { LocationContext } from "../../../context/LocationContext";

const CRM_URL = "/api/contact";

export default function FormConsultationSmall({
  handleSubmit,
  form,
  theme,
  loading,
  referralCode,
  showModal,
  setShowModal,
  submitButton = "Get Started",
  dispatchDestination,
  formId,
  darkLabels,
  isIOS = false
}) {
  const [urlParams, setUrlParams] = useState(null);
  const [selectedShowroom, setSelectedShowroom] = useState(null);
  const [showroomData, setShowroomData] = useState(null);
  const location = useContext(LocationContext);
  const initializedRef = useRef(false);

  // Memoize showroomOptions to prevent recreation on every render
  const showroomOptions = useMemo(() => {
    return jsonData.features
      .filter(location => {
        if (location.properties.dealer === 'yes') return false;
        return location.properties.city && location.properties.state;
      })
      .map(location => {
        const city = location.properties.city.trim();
        const state = location.properties.state.trim();
        return {
          value: `${city}, ${state}`,
          label: `${city}, ${state}`,
          data: {
            ...location,
            properties: {
              ...location.properties,
              city,
              state
            }
          }
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, []); // Empty dependency array since jsonData is static

  // Initialize showroom selection once
  useEffect(() => {
    if (!initializedRef.current && location) {
      console.log(`FormConsultationSmall (${formId}): Initializing showroom selection, isIOS:`, isIOS);
      
      // Get user's actual location from localStorage
      let userLocation = { city: '', state: '' };
      try {
        const storedLocation = localStorage.getItem('usersLocationData');
        if (storedLocation) {
          userLocation = JSON.parse(storedLocation);
          console.log(`FormConsultationSmall (${formId}): User location from localStorage:`, userLocation);
        }
      } catch (error) {
        console.error(`FormConsultationSmall (${formId}): Error reading user location:`, error);
      }

      // Get closest showroom from localStorage or LocationContext
      try {
        // Prefer LocationContext over localStorage
        if (location && location.city && location.state) {
          const showroomValue = `${location.city}, ${location.state}`;
          const matchingShowroom = showroomOptions.find(option => 
            option.value === showroomValue
          );

          if (matchingShowroom) {
            console.log(`FormConsultationSmall (${formId}): Setting initial showroom from LocationContext:`, matchingShowroom.data);
            setSelectedShowroom(showroomValue);
            setShowroomData(matchingShowroom.data);
            
            // Use a timeout for iOS devices to ensure the form is ready
            const timeoutDuration = isIOS ? 300 : 0;
            
            setTimeout(() => {
              form.setFieldsValue({
                showroom: showroomValue,
                tenantId: matchingShowroom.data.properties.tenantId,
                city: userLocation.city || '',
                state: userLocation.state || ''
              });
              
              // For iOS, double-check the values were set correctly
              if (isIOS) {
                setTimeout(() => {
                  const currentValues = form.getFieldsValue();
                  console.log(`FormConsultationSmall (${formId}): iOS form values check:`, currentValues);
                  
                  // If values weren't set correctly, try again
                  if (!currentValues.showroom || currentValues.showroom !== showroomValue) {
                    console.log(`FormConsultationSmall (${formId}): iOS retry setting form values`);
                    form.setFieldsValue({
                      showroom: showroomValue,
                      tenantId: matchingShowroom.data.properties.tenantId,
                      city: userLocation.city || '',
                      state: userLocation.state || ''
                    });
                  }
                }, 200);
              }
              
              initializedRef.current = true;
            }, timeoutDuration);
            
            return; // Exit early if we found a match in LocationContext
          }
        }
        
        // Fall back to localStorage if LocationContext didn't have valid data
        const storedShowroom = localStorage.getItem('locationFetched');
        if (storedShowroom) {
          const closestShowroom = JSON.parse(storedShowroom);
          const showroomValue = `${closestShowroom.properties.city}, ${closestShowroom.properties.state}`;
          const matchingShowroom = showroomOptions.find(option => 
            option.value === showroomValue
          );

          if (matchingShowroom) {
            console.log(`FormConsultationSmall (${formId}): Setting initial showroom from localStorage:`, matchingShowroom.data);
            setSelectedShowroom(showroomValue);
            setShowroomData(matchingShowroom.data);
            
            // Use a timeout for iOS devices to ensure the form is ready
            const timeoutDuration = isIOS ? 300 : 0;
            
            setTimeout(() => {
              form.setFieldsValue({
                showroom: showroomValue,
                tenantId: matchingShowroom.data.properties.tenantId,
                city: userLocation.city || '',
                state: userLocation.state || ''
              });
              
              // For iOS, double-check the values were set correctly
              if (isIOS) {
                setTimeout(() => {
                  const currentValues = form.getFieldsValue();
                  console.log(`FormConsultationSmall (${formId}): iOS form values check from localStorage:`, currentValues);
                  
                  // If values weren't set correctly, try again
                  if (!currentValues.showroom || currentValues.showroom !== showroomValue) {
                    console.log(`FormConsultationSmall (${formId}): iOS retry setting form values from localStorage`);
                    form.setFieldsValue({
                      showroom: showroomValue,
                      tenantId: matchingShowroom.data.properties.tenantId,
                      city: userLocation.city || '',
                      state: userLocation.state || ''
                    });
                  }
                }, 200);
              }
              
              initializedRef.current = true;
            }, timeoutDuration);
          }
        }
      } catch (error) {
        console.error(`FormConsultationSmall (${formId}): Error initializing showroom:`, error);
      }
    }
  }, [location, form, showroomOptions, formId, isIOS]);

  // Handle URL params separately
  useEffect(() => {
    if (typeof window !== "undefined") {
      const queryString = window?.location?.search;
      const urlParams = new URLSearchParams(queryString);
      if (queryString) {
        let obj = {};
        const entries = urlParams.entries();
        for (const entry of entries) {
          obj[entry[0]] = entry[1];
        }
        setUrlParams(obj);
      }
    }

    return () => {
      setUrlParams(null);
    };
  }, []); // Only run once on mount

  const handleShowroomChange = (value) => {
    const selectedOption = showroomOptions.find(option => option.value === value);
    if (selectedOption) {
      console.log('Selected showroom:', selectedOption.data);
      setSelectedShowroom(value);
      setShowroomData(selectedOption.data);
      
      // Update form with showroom data
      form.setFieldsValue({ 
        showroom: value,
        tenantId: selectedOption.data.properties.tenantId,
        city: selectedOption.data.properties.city.trim(),
        state: selectedOption.data.properties.state.trim()
      });
    }
  };

  const onFinish = async (values) => {
    const selectedOption = showroomOptions.find(option => option.value === values.showroom);
    
    if (!selectedOption) {
      console.error('No showroom selected');
      return;
    }

    // Get user's actual location from localStorage
    let userLocation = { city: '', state: '' };
    try {
      const storedLocation = localStorage.getItem('usersLocationData');
      console.log('Retrieved user location from localStorage:', storedLocation);
      if (storedLocation) {
        userLocation = JSON.parse(storedLocation);
        console.log('Parsed user location:', userLocation);
      } else {
        console.warn('No user location found in localStorage');
      }
    } catch (error) {
      console.error('Error reading user location:', error);
    }

    // Get all UTM sessions from localStorage
    let utmSessions = [];
    try {
      const storedUtmData = localStorage.getItem('utm_data');
      if (storedUtmData) {
        utmSessions = JSON.parse(storedUtmData);
        console.log('Retrieved UTM sessions:', utmSessions);
      }
    } catch (error) {
      console.error('Error reading UTM sessions:', error);
    }

    // Create submission data object with user's actual location and all UTM sessions
    const submissionData = {
      ...values,
      ...urlParams,
      city: userLocation.city || '',
      state: userLocation.state || '',
      tenantId: selectedOption.data.properties.tenantId,
      selectedShowroom: values.showroom, // Keep track of selected showroom separately
      utm_sessions: JSON.stringify(utmSessions) // Add all UTM sessions
    };

    console.log('Form values:', values);
    console.log('User location:', userLocation);
    console.log('Final submission data:', submissionData);

    await handleSubmit(submissionData);

    // After submission, maintain selectedShowroom state
    setSelectedShowroom(values.showroom);
    setShowroomData(selectedOption.data);
  };

  return (
    <div>
      <ModalContactSuccess
        visible={showModal}
        onRequestClose={() => setShowModal(false)}
      />
      <div className={darkLabels ? "dark-labels" : ""}>
        <FormContainer 
          name={formId} 
          size="small" 
          onFinish={onFinish}
          form={form}
          $darkLabels={darkLabels}
          initialValues={{
            tenantId: showroomData?.properties?.tenantId
          }}
        >
          <FormItem
            name="full_name"
            rules={[{ required: true, message: "Full Name required" }]}
            label={<StyledLabel $darkLabels={darkLabels}>Full Name</StyledLabel>}
          >
            <StyledInput placeholder="Jane/John Doe" />
          </FormItem>
          <FormItem
            name="email"
            rules={[
              { required: true, message: "Email required" },
              { type: "email", message: "Not a valid email" }
            ]}
            label={<StyledLabel $darkLabels={darkLabels}>Email Address</StyledLabel>}
          >
            <StyledInput placeholder="user@email.com" />
          </FormItem>
          <FormItem
            name="phone"
            rules={[{ required: true, message: "Phone required" }]}
            label={<StyledLabel $darkLabels={darkLabels}>Phone Number</StyledLabel>}
            normalize={(value) => {
              if (!value) return value;
              const onlyNums = value.replace(/[^\d]/g, "");
              if (onlyNums.length >= 4 && onlyNums.length <= 6) {
                return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
              }
              if (onlyNums.length > 6 && onlyNums.length <= 10) {
                return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
              }
              if (onlyNums.length > 10) {
                return `(${onlyNums.slice(1, 4)}) ${onlyNums.slice(4, 7)}-${onlyNums.slice(7, 11)}`;
              }
              return onlyNums;
            }}
          >
            <StyledInput placeholder="202-555-5555" />
          </FormItem>
          <FormItem
            name="showroom"
            rules={[{ required: true, message: "Please select a showroom" }]}
            label={<StyledLabel $darkLabels={darkLabels}>Nearest Showroom</StyledLabel>}
          >
            <StyledSelect
              placeholder="Select Showroom"
              options={showroomOptions}
              value={selectedShowroom}
              onChange={handleShowroomChange}
              dropdownStyle={{ zIndex: 9999999 }}
              getPopupContainer={typeof document !== 'undefined' ? () => document.body : undefined}
            />
          </FormItem>
          <FormItem name="tenantId" hidden>
            <Input type="hidden" />
          </FormItem>
          <FormItem name="city" hidden>
            <Input type="hidden" />
          </FormItem>
          <FormItem name="state" hidden>
            <Input type="hidden" />
          </FormItem>
          <FormItem
            name="message"
            label={<StyledLabel $darkLabels={darkLabels}>Comments</StyledLabel>}
          >
            <StyledTextArea
              placeholder="Comments"
              rows={3}
            />
          </FormItem>
          <FormItem>
            <PButton
              data-cy="submit"
              ptype="primary"
              pname={submitButton || "GET STARTED"}
              width="100%"
              bgColor={theme.colors.prune}
              hoverBgColor={theme.colors.hoverColor2}
              height="20px"
              shadow="yes"
              htmlType="submit"
            />
          </FormItem>
          
        </FormContainer>
      </div>
      <Loading loading={loading} />
    </div>
  );
}
