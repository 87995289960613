import { useEffect, useState, useContext, useRef } from "react";
import {
  FormContainer, FormItem, StyledInput, PButton, StyledTextArea, EEAT,
  EEATWRAP,
  EEATText,
  StyledLabel,
  StyledSelect,
} from "./styled";
import { Row, Col, Input, Form } from "antd";
import Image from 'next/image';
import Loading from "../../../components/Loading";
import ModalContactSuccess from "../../Modals/ModalContactSuccess";
import { jsonData } from "../../../components/LocationsMap/_data";
import { LocationContext } from "../../../context/LocationContext";

// Filter locations from jsonData - include all non-dealer locations
console.log('All locations:', jsonData.features.length);
const showroomOptions = jsonData.features
  .filter(location => {
    // Skip dealer locations
    if (location.properties.dealer === 'yes') return false;
    // Ensure we have valid city and state
    return location.properties.city && location.properties.state;
  })
  .map(location => {
    const city = location.properties.city.trim();
    const state = location.properties.state.trim();
    return {
      value: `${city}, ${state}`,
      label: `${city}, ${state}`,
      data: {
        ...location,
        properties: {
          ...location.properties,
          city,
          state
        }
      }
    };
  })
  .sort((a, b) => a.label.localeCompare(b.label));  // Sort alphabetically

console.log('Final options:', showroomOptions);

export default function FormConsultationLarge({
  handleSubmit,
  form,
  theme,
  loading,
  referralCode,
  showModal,
  setShowModal,
  submitButton = "Get Started",
  dispatchDestination,
  formId,
  darkLabels,
  isIOS = false
}) {
  const [urlParams, setUrlParams] = useState(null);
  const [selectedShowroom, setSelectedShowroom] = useState(null);
  const [showroomData, setShowroomData] = useState(null);
  const location = useContext(LocationContext);
  const initializedRef = useRef(false);

  // Watch for location changes and update showroom selection
  useEffect(() => {
    if (location && !initializedRef.current) {
      console.log(`FormConsultationLarge (${formId}): Initializing showroom selection, isIOS:`, isIOS);
      
      // Get user's actual location from localStorage
      let userLocation = { city: '', state: '' };
      try {
        const storedLocation = localStorage.getItem('usersLocationData');
        if (storedLocation) {
          userLocation = JSON.parse(storedLocation);
          console.log(`FormConsultationLarge (${formId}): User location from localStorage:`, userLocation);
        }
      } catch (error) {
        console.error(`FormConsultationLarge (${formId}): Error reading user location:`, error);
      }

      // Prefer LocationContext over localStorage
      if (location && location.city && location.state) {
        const showroomValue = `${location.city}, ${location.state}`;
        const matchingShowroom = showroomOptions.find(option => 
          option.value === showroomValue
        );

        if (matchingShowroom) {
          console.log(`FormConsultationLarge (${formId}): Setting initial showroom from LocationContext:`, matchingShowroom.data);
          setSelectedShowroom(showroomValue);
          setShowroomData(matchingShowroom.data);
          
          // Use a timeout for iOS devices to ensure the form is ready
          const timeoutDuration = isIOS ? 300 : 0;
          
          setTimeout(() => {
            form.setFieldsValue({
              showroom: showroomValue,
              tenantId: matchingShowroom.data.properties.tenantId,
              city: userLocation.city || '',
              state: userLocation.state || ''
            });
            
            // For iOS, double-check the values were set correctly
            if (isIOS) {
              setTimeout(() => {
                const currentValues = form.getFieldsValue();
                console.log(`FormConsultationLarge (${formId}): iOS form values check:`, currentValues);
                
                // If values weren't set correctly, try again
                if (!currentValues.showroom || currentValues.showroom !== showroomValue) {
                  console.log(`FormConsultationLarge (${formId}): iOS retry setting form values`);
                  form.setFieldsValue({
                    showroom: showroomValue,
                    tenantId: matchingShowroom.data.properties.tenantId,
                    city: userLocation.city || '',
                    state: userLocation.state || ''
                  });
                }
              }, 200);
            }
            
            initializedRef.current = true;
          }, timeoutDuration);
          
        } else {
          // Fall back to localStorage if LocationContext didn't have a matching showroom
          fallbackToLocalStorage();
        }
      } else {
        // Fall back to localStorage if LocationContext didn't have valid data
        fallbackToLocalStorage();
      }
      
      // Helper function to use localStorage as fallback
      function fallbackToLocalStorage() {
        try {
          const storedShowroom = localStorage.getItem('locationFetched');
          if (storedShowroom) {
            const closestShowroom = JSON.parse(storedShowroom);
            const showroomValue = `${closestShowroom.properties.city}, ${closestShowroom.properties.state}`;
            const matchingShowroom = showroomOptions.find(option => 
              option.value === showroomValue
            );

            if (matchingShowroom) {
              console.log(`FormConsultationLarge (${formId}): Setting initial showroom from localStorage:`, matchingShowroom.data);
              setSelectedShowroom(showroomValue);
              setShowroomData(matchingShowroom.data);
              
              // Use a timeout for iOS devices to ensure the form is ready
              const timeoutDuration = isIOS ? 300 : 0;
              
              setTimeout(() => {
                form.setFieldsValue({
                  showroom: showroomValue,
                  tenantId: matchingShowroom.data.properties.tenantId,
                  city: userLocation.city || '',
                  state: userLocation.state || ''
                });
                
                // For iOS, double-check the values were set correctly
                if (isIOS) {
                  setTimeout(() => {
                    const currentValues = form.getFieldsValue();
                    console.log(`FormConsultationLarge (${formId}): iOS form values check from localStorage:`, currentValues);
                    
                    // If values weren't set correctly, try again
                    if (!currentValues.showroom || currentValues.showroom !== showroomValue) {
                      console.log(`FormConsultationLarge (${formId}): iOS retry setting form values from localStorage`);
                      form.setFieldsValue({
                        showroom: showroomValue,
                        tenantId: matchingShowroom.data.properties.tenantId,
                        city: userLocation.city || '',
                        state: userLocation.state || ''
                      });
                    }
                  }, 200);
                }
                
                initializedRef.current = true;
              }, timeoutDuration);
            }
          }
        } catch (error) {
          console.error(`FormConsultationLarge (${formId}): Error reading closest showroom:`, error);
        }
      }
    }

    // Handle URL params
    if (typeof window !== "undefined") {
      const queryString = window?.location?.search;
      const urlParams = new URLSearchParams(queryString);
      if (queryString) {
        let obj = {};
        const entries = urlParams.entries();
        for (const entry of entries) {
          obj[entry[0]] = entry[1];
        }
        setUrlParams(obj);
      }
    }

    return () => {
      setUrlParams(null);
    };
  }, [location, form, showroomOptions, formId, isIOS]);

  const handleShowroomChange = (value) => {
    const selectedOption = showroomOptions.find(option => option.value === value);
    if (selectedOption) {
      console.log('Selected showroom:', selectedOption.data);
      setSelectedShowroom(value);
      setShowroomData(selectedOption.data);
      
      // Only update showroom and tenantId, not city/state
      form.setFieldsValue({ 
        showroom: value,
        tenantId: selectedOption.data.properties.tenantId
      });
    }
  };

  const onFinish = async (values) => {
    const selectedOption = showroomOptions.find(option => option.value === values.showroom);
    
    if (!selectedOption) {
      console.error('No showroom selected');
      return;
    }

    // Get user's actual location from localStorage
    let userLocation = { city: '', state: '' };
    try {
      const storedLocation = localStorage.getItem('usersLocationData');
      console.log('Retrieved user location from localStorage:', storedLocation);
      if (storedLocation) {
        userLocation = JSON.parse(storedLocation);
        console.log('Parsed user location:', userLocation);
      } else {
        console.warn('No user location found in localStorage');
      }
    } catch (error) {
      console.error('Error reading user location:', error);
    }

    // Create submission data object with user's actual location
    const submissionData = {
      ...values,
      ...urlParams,
      city: userLocation.city || '',
      state: userLocation.state || '',
      tenantId: selectedOption.data.properties.tenantId,
      selectedShowroom: values.showroom // Keep track of selected showroom separately
    };

    console.log('Form values:', values);
    console.log('User location:', userLocation);
    console.log('Final submission data:', submissionData);

    await handleSubmit(submissionData);

    // After submission, reset form but maintain showroom selection
    const currentShowroom = values.showroom;
    const currentTenantId = selectedOption.data.properties.tenantId;
    form.resetFields();
    form.setFieldsValue({
      showroom: currentShowroom,
      tenantId: currentTenantId,
      city: userLocation.city || '',
      state: userLocation.state || ''
    });
  };

  return (
    <div>
      <ModalContactSuccess
        visible={showModal}
        onRequestClose={() => setShowModal(false)}
      />
      <FormContainer name={formId} size="small" onFinish={onFinish} form={form}>
        <Row className="background-red" gutter={16}>
          <Col md={12} xs={24}>
            <FormItem
              validateTrigger={"onSubmit"}
              name={"full_name"}
              rules={[
                {
                  required: true,
                  message: "First and Last name required",
                },
              ]}
            >
              <StyledInput placeholder={"Full Name"} />
            </FormItem>
            <FormItem
              validateTrigger={"onBlur"}
              name={"email"}
              rules={[
                {
                  required: true,
                  message: "Email required",
                },
                {
                  type: "email",
                  message: "Not a valid email",
                },
              ]}
            >
              <StyledInput placeholder={"Email Address"} />
            </FormItem>
            <FormItem
              name={"phone"}
              rules={[
                {
                  required: true,
                  message: "Phone required",
                },
              ]}
              normalize={(value) => {
                const onlyNums = value.replace(/[^\d]/g, "");
                if (onlyNums.length >= 4 && onlyNums.length <= 6) {
                  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
                }
                if (onlyNums.length > 6 && onlyNums.length <= 10) {
                  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
                }
                if (onlyNums.length > 10) {
                  return `(${onlyNums.slice(1, 4)}) ${onlyNums.slice(4, 7)}-${onlyNums.slice(7, 11)}`;
                }
                return onlyNums;
              }}
            >
              <StyledInput placeholder={"Phone Number"} />
            </FormItem>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              name="showroom"
              rules={[{ required: true, message: "Please select a showroom" }]}
              label={<StyledLabel>Nearest Location</StyledLabel>}
            >
              <StyledSelect
                placeholder="Select Location"
                options={showroomOptions}
                onChange={handleShowroomChange}
              />
            </Form.Item>
            <Form.Item name="tenantId" hidden>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="city" hidden>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="state" hidden>
              <Input type="hidden" />
            </Form.Item>
          </Col>
        </Row>
        <FormItem>
          <PButton
            data-large-cy="submit"
            ptype="primary"
            pname={submitButton || "GET STARTED"}
            width="100%"
            bgColor={theme.colors.prune}
            hoverBgColor={theme.colors.hoverColor2}
            height="20px"
            shadow="yes"
            htmlType="submit"
            onSurface={true}
          />
        </FormItem>
        <EEATWRAP>
          <EEAT>
            <Image
              src="/images/eeat/EEAT-01.svg"
              width="40px"
              height="52px"
              className="iconImage"
              alt="40+ years experience"
            >
            </Image>
            <EEATText>40+ Years<br />Experience</EEATText>
          </EEAT>
          <EEAT>
            <Image
              src="/images/eeat/alt/EEAT-02.svg"
              width="40px"
              height="52px"
              className="iconImage"
              alt="lifetime limited warranty"
            >
            </Image>
            <EEATText>Limited<br /> Lifetime Warranty</EEATText>
          </EEAT>
          <EEAT>
            <Image
              src="/images/eeat/EEAT-03.svg"
              width="40px"
              height="52px"
              className="iconImage"
              alt="A+ BBB Rating"
            >
            </Image>
            <EEATText>A+ BBB<br />Rating</EEATText>
          </EEAT>
          <EEAT>
            <Image
              src="/images/eeat/alt/EEAT-04.svg"
              width="40px"
              height="52px"
              className="iconImage"
              alt="custom fit"
            >
            </Image>
            <EEATText>Custom<br />Fit</EEATText>
          </EEAT>
        </EEATWRAP>
      </FormContainer>
      <Loading loading={loading} />
    </div>
  );
}
