import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Form } from "antd";
import { withTheme } from "styled-components";
import FormConsultationSmall from "./small";
import FormConsultationLarge from "./large";
import { setGoogleAdwordsInfo, getCurrentSessionUtm } from "../../../utils/helper";
import { getQueryFromStorage } from "../../../hooks/useQueryCatcher";
import { useCookies } from "react-cookie";
import useFetch from "../../../hooks/useFetch";
import { eventFormSubmission } from "../../../services/analytics/ga4CustomEvents";
const EMAIL_URL = "/api/contact/email";
const CRM_URL = "/api/contact";
import TagManager from "react-gtm-module";
import ModalContactSuccess from "../../Modals/ModalContactSuccess";

const FormConsultation = ({ 
  theme, 
  variant, 
  referralCode, 
  submitButton, 
  dispatchDestination = "contact", 
  formId, 
  darkLabels,
  onFormSuccess,
  showModal: parentShowModal,
  setShowModal: parentSetShowModal,
  initialValues,
  form: externalForm,
  isFormReady = true,
  isIOS = false
}) => {
  const [cookie, setCookie] = useCookies(["converted"]);
  const { submitMultiple } = useFetch();
  const [form] = Form.useForm();
  const actualForm = externalForm || form;
  const [loading, setLoading] = useState(false);
  const [showLocalModal, setShowLocalModal] = useState(false);
  const isMounted = useRef(true);
  const formInitializedRef = useRef(false);

  // Set up cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Initialize form with initial values if provided
  useEffect(() => {
    if (!isMounted.current || formInitializedRef.current || !isFormReady) return;
    
    if (initialValues && Object.keys(initialValues).length > 0) {
      console.log(`FormConsultation (${formId}): Initializing form with values:`, initialValues);
      
      // Use a timeout to ensure the form is fully rendered
      const timeoutDuration = isIOS ? 300 : 100;
      
      setTimeout(() => {
        try {
          actualForm.setFieldsValue(initialValues);
          formInitializedRef.current = true;
          
          // For iOS, double-check the values were set correctly
          if (isIOS) {
            setTimeout(() => {
              const currentValues = actualForm.getFieldsValue();
              console.log(`FormConsultation (${formId}): iOS form values check:`, currentValues);
              
              // If values weren't set correctly, try again
              if (!currentValues.showroom || currentValues.showroom !== initialValues.showroom) {
                console.log(`FormConsultation (${formId}): iOS retry setting form values`);
                actualForm.setFieldsValue(initialValues);
              }
            }, 200);
          }
        } catch (error) {
          console.error(`FormConsultation (${formId}): Error initializing form:`, error);
        }
      }, timeoutDuration);
    }
  }, [initialValues, actualForm, isFormReady, isIOS, formId]);

  const handleSubmit = async (values) => {
    if (!isMounted.current) return;
    setLoading(true);
    
    try {
      const nameParts = values.full_name?.split(' ') || [];
      const firstName = nameParts[0] || '';
      const lastName = nameParts.slice(1).join(' ') || '';
      
      let userLocation = { city: '', state: '' };
      try {
        const storedLocation = localStorage.getItem('usersLocationData');
        if (storedLocation) {
          userLocation = JSON.parse(storedLocation);
        }
      } catch (error) {
        console.error('Error reading user location:', error);
      }

      // Get UTM data from current session and history
      const utmData = getCurrentSessionUtm() || {};
      let utmSessions = [];
      try {
        const storedUtmData = localStorage.getItem('utm_data');
        if (storedUtmData) {
          utmSessions = JSON.parse(storedUtmData);
          console.log('Retrieved UTM sessions:', utmSessions);
        }
      } catch (error) {
        console.error('Error reading UTM sessions:', error);
      }

      const currentShowroom = values.showroom;
      const currentTenantId = values.tenantId;
      
      const baseData = {
        ...values,
        ...utmData,
        utm_sessions: JSON.stringify(utmSessions),
        first_name: firstName,
        last_name: lastName,
        client: 'classyclosets',
        queries: getQueryFromStorage(),
        user_id: typeof window !== 'undefined' ? window.uniqueVisitorId || 'null' : 'null',
        visitorId: typeof window !== 'undefined' ? window.uniqueVisitorId || 'null' : 'null',
        confidenceScore: typeof window !== 'undefined' ? window.confidenceScore || 0 : 0,
        fingerprint: typeof window !== 'undefined' ? window.uniqueVisitorId || 'null' : 'null',
        user_city: userLocation.city,
        user_state: userLocation.state,
        city: userLocation.city,
        state: userLocation.state,
        device_type: isIOS ? 'iOS' : (typeof window !== 'undefined' && window.innerWidth <= 768 ? 'mobile' : 'desktop'),
        form_id: formId || 'consultation-form'
      };

      if (values.showroom) {
        const [showroomCity, showroomState] = values.showroom.split(', ');
        baseData.showroom_city = showroomCity;
        baseData.showroom_state = showroomState;
      }

      setGoogleAdwordsInfo(values);
      TagManager.dataLayer({
        dataLayer: {
          crmOrClassic: "classic",
        },
      });

      // Send GA4 event
      try {
        const eventParams = {
          email: values.email,
          phone: values.phone,
          crmOrClassic: "classic",
          page_location: window.location.href,
          page_title: document.title
        };
        await eventFormSubmission(window.location.href, "lead", eventParams);
        console.log('GA Event successfully sent');
      } catch (analyticsError) {
        console.error('GA Event failed:', analyticsError);
      }

      const requests = [
        {
          url: EMAIL_URL,
          method: "POST",
          data: baseData
        },
        {
          url: CRM_URL,
          method: "POST",
          data: baseData
        }
      ];

      await submitMultiple(requests);

      // Send Facebook conversion event
      await fetch('/api/crm/fb-conversion', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          first_name: values.full_name,
          email: values.email,
          phone: values.phone,
          event_time: Date.now()
        })
      });

      if (!isMounted.current) return;

      setCookie("converted", "step_1", {
        path: "/",
        maxAge: 86400 * 7
      });
      
      if (typeof onFormSuccess === 'function') {
        onFormSuccess();
      } else if (typeof parentSetShowModal === 'function') {
        parentSetShowModal(true);
      } else {
        setShowLocalModal(true);
      }

      actualForm.resetFields();
      if (currentShowroom) {
        actualForm.setFieldsValue({
          showroom: currentShowroom,
          tenantId: currentTenantId,
          city: userLocation.city || '',
          state: userLocation.state || ''
        });
      }

    } catch (error) {
      console.error('Form submission error:', error);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  const handleCloseModal = () => {
    if (!isMounted.current) return;
    if (typeof parentSetShowModal === 'function') {
      parentSetShowModal(false);
    } else {
      setShowLocalModal(false);
    }
  };

  const variants = ["small", "large"];
  variant = variants.includes(variant) ? variant : variants[0];

  const showSuccessModal = parentShowModal || showLocalModal;

  // Only render the form if it's ready (for better performance on mobile)
  if (!isFormReady && !formInitializedRef.current) {
    console.log(`FormConsultation (${formId}): Form not ready yet, waiting...`);
    return <div className="form-loading">Loading form...</div>;
  }

  return (
    <>
      {variant === "small" ? (
        <FormConsultationSmall
          handleSubmit={handleSubmit}
          loading={loading}
          form={actualForm}
          theme={theme}
          referralCode={referralCode}
          darkLabels={darkLabels}
          submitButton={submitButton}
          isIOS={isIOS}
        />
      ) : (
        <FormConsultationLarge
          handleSubmit={handleSubmit}
          loading={loading}
          form={actualForm}
          theme={theme}
          referralCode={referralCode}
          darkLabels={darkLabels}
          submitButton={submitButton}
          isIOS={isIOS}
        />
      )}
      <ModalContactSuccess 
        open={showSuccessModal}
        onRequestClose={handleCloseModal}
      />
    </>
  );
};

FormConsultation.propTypes = {
  theme: PropTypes.object,
  variant: PropTypes.string,
  referralCode: PropTypes.string,
  submitButton: PropTypes.string,
  dispatchDestination: PropTypes.string,
  formId: PropTypes.string,
  darkLabels: PropTypes.bool,
  handleSubmit: PropTypes.func,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  initialValues: PropTypes.object,
  form: PropTypes.object,
  isFormReady: PropTypes.bool,
  isIOS: PropTypes.bool
};

export default withTheme(FormConsultation);
